export const TEAM_CELL_DATA = { name: "", position: "", update_count: 0, is_volunteer: false };
export const TEAM_BOX_COLORS = {
  0: "",
  1: "#B9EBE4",
  2: "#96B8DE",
  3: "#fed7aa",
  4: "#c4b5fd",
  5: "#f9a8d4",
  6: "white",
};
